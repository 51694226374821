.flex-container-scoreboard {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: row;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.grid-wrapper-scoreboard {
  height: 100vh;
  /* background-image: url(../images/background.png); */
  background-size: cover;
  font-family: "Oswald", sans-serif;
  text-align: center;
  justify-items: center;
  /*text-transform: uppercase;*/
  /*padding: 0;*/
  /*margin: 0;*/
  overflow: auto;
}

.grid-container-scoreboard {
  background: transparent;
  /* box-shadow: 0 0.1em 0 0 rgba(0,0,0,0.1); */
  margin: auto;
  display: grid;
  /*grid-template-rows: repeat(auto-fill, minmax(100,1));*/
  grid-gap: 20px 50px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  transform: translateY(50%);
  padding: 0 10px 20px;
  clear: both;
}
